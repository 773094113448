import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { GlobalStyles } from 'themes';
import { BaseCSS } from 'styled-bootstrap-grid';

import Navbar from 'components/shared/Navbar';
import Footer from 'components/shared/Footer';

import { Content } from './styled';

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          link={[{ rel: 'stylesheet', href: 'https://use.typekit.net/vni5jed.css' }]}
        >
          <html lang="en" />
        </Helmet>

        <Navbar siteTitle={data.site.siteMetadata.title} />
        <Content>{children}</Content>
        <Footer />

        <BaseCSS />
        <GlobalStyles />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
