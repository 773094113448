import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { colors, media } from 'themes';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;
  margin-bottom: 80px;

  ${media.md`
    justify-content: center;
  `}

  ${media.sm`
    justify-content: space-between;
  `}
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled(GatsbyLink)`
  width: 200px;

  img {
    width: 100%;
    height: auto;
  }
`;

export const Link = styled(GatsbyLink)`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.mineGrey};
  text-decoration: none;

  ${({ active }) => active && `
    color: ${colors.caribbeanGreen};
  `}

  & + & {
    margin-left: 60px;
  }
`;

export const GetEstimateButton = styled.a`
	border: 1px solid ${colors.caribbeanGreen};
	border-radius: 18px;
  padding: 9px 22px 8px 22px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: ${colors.mineGrey};
  text-decoration: none;
  transition: all 200ms;

  ${media.md`
    display: none;
  `};

  &:hover {
    cursor: pointer;
    background: ${colors.caribbeanGreen};
    color: white;
  }
`;

export const Partner = styled.div`
  margin-right: 36px;

  ${media.md`
    margin-right: 0;
    margin-left: 36px;
  `}

  ${media.sm`
    margin-left: 20px;

    img {
      max-width: 45px;
    }
  `}
`;
