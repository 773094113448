import React from 'react';
import Container from 'styled-bootstrap-grid/dist/Container';
import { scroller } from 'react-scroll';

import FileNyc from 'images/nyc.png';
import FileNyc2x from 'images/nyc@2x.png';
import FileClutch from 'images/clutch.png';
import FileClutch2x from 'images/clutch@2x.png';
import FileLogo from 'images/logo.svg';

import { Wrapper, Logo, GetEstimateButton, Right, Partner } from './styled';

const Navbar = () => (
  <Container>
    <Wrapper>
      <Logo to="/">
        <img src={FileLogo} alt="Innovatica" />
      </Logo>

      <Right>
        <Partner>
          <img srcSet={`${FileNyc} 1x, ${FileNyc2x} 2x`} alt="NYC" />
        </Partner>

        <Partner>
          <a href="https://clutch.co/profile/innovatica" target="_blank" rel="noopener noreferrer">
            <img srcSet={`${FileClutch} 1x, ${FileClutch2x} 2x`} alt="Clutch" />
          </a>
        </Partner>

        <GetEstimateButton
          onClick={() =>
            scroller.scrollTo('contact', {
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart'
            })
          }
        >
          Get estimate
        </GetEstimateButton>
      </Right>
    </Wrapper>
  </Container>
);

export default Navbar;
