import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, media } from 'themes';

export const Wrapper = styled.footer`
  padding: 90px 0 84px;
  background: white;
  margin-bottom: 149px;

  ${media.md`
    padding-top: 56px;
    margin-bottom: 0;
  `}
`;

export const Logo = styled(Link)`
  display: inline-block;
  width: 200px;

  ${media.sm`
    margin-bottom: 48px;
  `}

  img {
    width: 100%;
  }
`;

export const ContactDetails = styled.table`
  ${media.md`
    float: right;
    text-align: right;
  `}

  ${media.sm`
    float: none;
    text-align: left;
  `}
`;

export const Address = styled.div`
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;

  ${media.md`
    text-align: right;
    margin-top: 40px;
  `}

  ${media.sm`
    text-align: left;
    margin-top: 40px;
  `}
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Copyright = styled.div`
  color: ${colors.subtleGrey};
  font-size: 12px;
  line-height: 15px;
`;

export const ContactValue = styled.td`
  color: ${colors.caribbeanGreen};
  font-weight: bold;
  padding-left: 40px;
  line-height: 32px;
`;

export const BackToTop = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background-color: ${colors.caribbeanGreen};
  box-shadow: 0 5px 10px -5px ${colors.caribbeanGreen};
  cursor: pointer;
  transition: transform 150ms;

  &:hover {
    transform: scale(1.1);

    ${media.sm`
      transform: none;
    `}
  }
`;

export const Tags = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: -1;
  background: ${colors.buntingBlue};
  color: ${colors.mutedViolet};
  padding: 36px 0;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;

  ${media.sm`
    display: none;
  `}
`;

export const TagsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const Partners = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 0 50px;
`;

export const Partner = styled.div`
  margin-right: 36px;
`;

export const Title = styled.h2`
  color: #2b2b2b;
  font-size: 32px;
  font-weight: bold;
  line-height: 39px;
  text-align: center;
  margin-bottom: 88px;
`;
